import React from "react";
import styled from "styled-components";

const IntroSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  padding-top: 3rem; /* Adds space for the fixed navbar */
  min-height: calc(
    100vh - 8rem
  ); /* Ensures the section takes up the full height minus the navbar */
  text-align: left;
  background: ${({ theme }) => theme.colors.background};
  position: relative; /* Make sure the section can contain the swirl animation */
  overflow: hidden;
  flex-wrap: wrap; /* Allows wrapping for responsive layout */
`;

const IntroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Distributes space between items */
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column; /* Stacks items vertically on mobile */
    align-items: center;
  }
`;

const IntroText = styled.div`
  max-width: 50%;
  flex: 1;
  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center; /* Centers text on mobile */
    margin-right: 0; /* Removes right margin on mobile */
    order: 2; /* Ensures text is below the image on mobile */
  }
`;

const IntroTitle = styled.h1`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.text};
`;

const IntroSubtitle = styled.h2`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const IntroParagraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
`;

const IntroButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const IntroImageWrapper = styled.div`
  width: 250px; /* Adjusted size for larger image */
  height: 250px; /* Adjusted size for larger image */
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 100px; /* Moves the image 100px to the right of the text */
  @media (max-width: 768px) {
    margin-left: 0; /* Removes left margin on mobile */
    margin-bottom: 1rem; /* Adds bottom margin on mobile */
    order: 1; /* Ensures image is above the text on mobile */
  }
`;

const IntroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area and maintains aspect ratio */
`;

const Intro: React.FC = () => {
  return (
    <IntroSection>
      <IntroContainer>
        <IntroText>
          <IntroTitle>Hello! I am</IntroTitle>
          <IntroSubtitle>Mark Allison</IntroSubtitle>
          <IntroSubtitle>Software Engineer • Developer</IntroSubtitle>
          <IntroParagraph>
            I'm a passionate Software Engineer based in
            Belfast, Northern Ireland, currently studying Computer Science at Ulster University
            with a strong interest in Backend Development and Will be Graduating in 2025. I'm looking forward
            to exploring new opportunities to enhance my skills.

            Check out my projects below or get in touch to discuss potential collaborations.
          </IntroParagraph>
          <IntroButton>Get my resume</IntroButton>
        </IntroText>
        <IntroImageWrapper>
          <IntroImage
            src="/images/my_face.jpg"
            alt="Profile Picture"
          />
        </IntroImageWrapper>
      </IntroContainer>
    </IntroSection>
  );
};

export default Intro;
