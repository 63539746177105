import React from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const SkillsSection = styled.section`
  padding: 4rem 2rem;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
`;

const SkillsTitle = styled(motion.h2)`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const SkillsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SkillsCategory = styled(motion.div)`
  margin-bottom: 2rem;
`;

const CategoryTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const SkillsList = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SkillItem = styled(motion.div)`
  background-color: ${({ theme }) =>
    theme.colors.secondary}; /* Use theme color */
  color: ${({ theme }) => theme.colors.text};
  padding: 0.75rem 1.5rem; /* Increased padding for bigger buttons */
  margin: 0.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: default;
  transition:
    background-color 0.3s,
    transform 0.3s; /* Smooth transition for hover effects */

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    transform: scale(1.05); /* Slightly increase size on hover */
  }
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const titleVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const categoryVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const itemVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
};

const Skills = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const backendSkills = [
    "PHP",
    "Perl",
    "Python",
    "Go",
    "Java",
    "Laravel",
    "Flask",
  ];
  const frontendSkills = ["HTML", "CSS", "JavaScript", "React", "Angular"];
  const toolsAndTechnologies = ["Docker", "Git", "Jenkins"];

  return (
    <SkillsSection id="skills" ref={ref}>
      <SkillsTitle initial="hidden" animate={controls} variants={titleVariants}>
        Skills
      </SkillsTitle>
      <SkillsContainer
        initial="hidden"
        animate={controls}
        variants={containerVariants}
      >
        <SkillsCategory variants={categoryVariants}>
          <CategoryTitle>Backend Development</CategoryTitle>
          <SkillsList>
            {backendSkills.map((skill) => (
              <SkillItem key={skill} variants={itemVariants}>
                {skill}
              </SkillItem>
            ))}
          </SkillsList>
        </SkillsCategory>
        <SkillsCategory variants={categoryVariants}>
          <CategoryTitle>Frontend Development</CategoryTitle>
          <SkillsList>
            {frontendSkills.map((skill) => (
              <SkillItem key={skill} variants={itemVariants}>
                {skill}
              </SkillItem>
            ))}
          </SkillsList>
        </SkillsCategory>
        <SkillsCategory variants={categoryVariants}>
          <CategoryTitle>Tools & Technologies</CategoryTitle>
          <SkillsList>
            {toolsAndTechnologies.map((skill) => (
              <SkillItem key={skill} variants={itemVariants}>
                {skill}
              </SkillItem>
            ))}
          </SkillsList>
        </SkillsCategory>
      </SkillsContainer>
    </SkillsSection>
  );
};

export default Skills;
