import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaMoon, FaSun, FaBars, FaTimes } from "react-icons/fa";

interface NavbarProps {
  toggleTheme: () => void;
  isDarkMode: boolean;
}

const Nav = styled(motion.nav)`
  background: none;
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
`;

const NavLinks = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background: ${({ theme }) => theme.colors.background};
    max-height: ${({ open }) => (open ? "300px" : "0")};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    justify-content: center;
  }
`;

const NavLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  margin: 0 1rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
    text-align: center;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the icon horizontally */
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    margin-left: 0; /* Reset margin for mobile view */
    font-size: 2rem; /* Increase icon size for better visibility on mobile */
  }
`;

const BurgerButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  font-size: 1.5rem;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the icon horizontally and vertically */
    font-size: 2rem; /* Increase icon size for better visibility on mobile */
  }
`;

const Navbar: React.FC<NavbarProps> = ({ toggleTheme, isDarkMode }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = (event: React.MouseEvent, targetId: string) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 10,
        behavior: "smooth",
      });
    }
    setMenuOpen(false);
  };

  return (
    <Nav initial={{ y: -250 }} animate={{ y: 0 }}>
      <BurgerButton onClick={() => setMenuOpen(!menuOpen)}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </BurgerButton>
      <NavLinks open={menuOpen}>
        <NavLink href="#" onClick={(e) => handleScroll(e, "about")}>
          About
        </NavLink>
        <NavLink href="#" onClick={(e) => handleScroll(e, "projects")}>
          Projects
        </NavLink>
        <NavLink href="#" onClick={(e) => handleScroll(e, "skills")}>
          Skills
        </NavLink>
        <NavLink href="#" onClick={(e) => handleScroll(e, "contact")}>
          Contact
        </NavLink>
        <NavLink href="Mark_Resume.pdf" target="_blank">
          Resume
        </NavLink>
        <ToggleButton onClick={toggleTheme}>
          {isDarkMode ? <FaSun /> : <FaMoon />}
        </ToggleButton>
      </NavLinks>
    </Nav>
  );
};

export default Navbar;
