import React from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 100vh; /* Ensures the section takes up full height of the viewport */
  text-align: center;
  background: ${({ theme }) => theme.colors.background};
`;

const AboutTitle = styled(motion.h2)`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: left;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AboutColumn = styled(motion.div)`
  max-width: 30%;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 2rem;
  }
`;

const AboutSubtitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const AboutParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
};

const titleVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const columnVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const About = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.1, // Trigger when 10% of the section is visible
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <AboutSection id="about" ref={ref}>
      <AboutTitle initial="hidden" animate={controls} variants={titleVariants}>
        About Me
      </AboutTitle>
      <AboutContainer as={motion.div} initial="hidden" animate={controls} variants={containerVariants}>
        <AboutColumn variants={columnVariants}>
          <AboutSubtitle>Past</AboutSubtitle>
          <AboutParagraph>
            Been interesting in programming from a very early age and was given help back in highschool to begin programming where i really learnt to enjoy the entirety of creating software which allowed me to persue a Level 3 qualificaiton at the belfast met specialising in software development before eventually heading to university
          </AboutParagraph>
        </AboutColumn>
        <AboutColumn variants={columnVariants}>
          <AboutSubtitle>Present</AboutSubtitle>
          <AboutParagraph>
            In the present day i am currently studying at the university of ulster in my final year of my degree in software engineering where i have been able to learn a lot of new skills and technologies that i have been able to apply to my own projects. I was able in this time to complete a year and a half full-time work experience at proofpoint where I really expanded my knowledge and improved as a individual
          </AboutParagraph>
        </AboutColumn>
        <AboutColumn variants={columnVariants}>
          <AboutSubtitle>Future</AboutSubtitle>
          <AboutParagraph>
            In the future i want to just become the best person i can be and continue to learn and grow as a person and as a developer. I want to continue to work on my own projects and continue to learn new technologies and languages to improve my skills and knowledge, Ideally in the distant future I would like to travel and work abroad possible in the Nordic region or Canada to see what working in the software industry is like outside of the UK and to live and take in a different culture
          </AboutParagraph>
        </AboutColumn>
      </AboutContainer>
    </AboutSection>
  );
};

export default About;
